import Select from '@paljs/ui/Select';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Checkbox } from '@paljs/ui/Checkbox';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Auth from '../../components/Auth';
import SEO from '../../components/SEO';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../utils/fb';

const Input = styled(InputGroup)`
  margin-bottom: 2rem;
`;

export default function Register() {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    company: '',
    position: '',
  });
  const { name, email, password, company, position } = values;

  const [errors, setErrors] = useState({
    nameErr: '',
    emailErr: '',
    passwordErr: '',
    companyErr: '',
    positionErr: '',
  });
  const { nameErr, emailErr, passwordErr, companyErr, positionErr } = errors;
  const [success, setSuccess] = useState(false);

  const onCheckbox = () => {
    // v will be true or false
  };
  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setErrors({
      nameErr: '',
      emailErr: '',
      passwordErr: '',
      companyErr: '',
      positionErr: '',
    });
    setSuccess(false);

    if (!name) {
      setErrors({ ...errors, nameErr: 'Name is required' });
      return;
    }
    if (!email) {
      setErrors({ ...errors, emailErr: 'Email is required' });
      return;
    }
    if (!password) {
      setErrors({ ...errors, passwordErr: 'Password is required' });
      return;
    }
    if (!company) {
      setErrors({ ...errors, companyErr: 'Company is required' });
      return;
    }
    if (!position) {
      setErrors({ ...errors, positionErr: 'Position is required' });
      return;
    }
    if (!validateEmail(email)) {
      setErrors({ ...errors, emailErr: 'Please provide a valid email' });
      return;
    }
    try {
      const res = await fetch('/.netlify/functions/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (res.ok) {
        await addDoc(collection(db, 'requests'), {
          name,
          email,
          password,
          company,
          position,
          role: { value: 'Instructor', label: 'Instructor' },
          notes: '',
        });

        setSuccess(true);
        setValues({ name: '', email: '', password: '', company: '', position: '' });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Auth title="Request to create new account">
      <SEO title="Register" />
      <form onSubmit={handleSubmit} className={success ? 'form-submitted' : ''}>
        <p className="accountText">
          Upon filling this form, your request will be logged within our system. Our team will reach out if we require
          any additional information.
        </p>
        <Input fullWidth>
          <input type="text" placeholder="Full Name" name="name" value={name} onChange={handleChange} />
        </Input>
        {nameErr && <p>{nameErr}</p>}
        <Input fullWidth>
          <input type="email" placeholder="Email Address" name="email" value={email} onChange={handleChange} />
        </Input>
        {emailErr && <p>{emailErr}</p>}

        <Input fullWidth>
          <input type="password" placeholder="Password" name="password" value={password} onChange={handleChange} />
        </Input>
        {passwordErr && <p>{passwordErr}</p>}
        <Input fullWidth>
          <input type="text" placeholder="Company" name="company" value={company} onChange={handleChange} />
        </Input>
        {companyErr && <p>{companyErr}</p>}
        <Input fullWidth>
          <input type="text" placeholder="Position" name="position" value={position} onChange={handleChange} />
        </Input>
        {positionErr && <p>{positionErr}</p>}

        <Button status="Success" type="submit" shape="SemiRound" fullWidth>
          Request Account for SOSA E-Learning
        </Button>
      </form>
      {success && <p className='lead'>Thank you for account creation request! We'll be in touch soon.</p>}
      <p>
        Already have an account? <Link to="/auth/login">Log In</Link>
      </p>
    </Auth>
  );
}
